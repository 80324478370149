@mixin shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
	-webkit-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
	-moz-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
	box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
}
@mixin trans($t: 0.5s) {
	-webkit-transition: all $t ease;
	-ms-transition: all $t ease;
	transition: all $t ease;
}

//MENU breakpoints
@mixin b-menu1() {
	@media (max-width: 1850px) {
		@content;
	}
}
@mixin b-menu2() {
	@media (max-width: 1300px) {
		@content;
	}
}
@mixin b-menuM() {
	//for mobile
	@media (max-width: 960px) {
		@content;
	}
}
//only display portrait orientation
@mixin b-PORT() {
	@media (orientation: portrait) {
		@content;
	}
}

//only display landscape orientation
@mixin b-LAND() {
	@media (orientation: landscape) {
		@content;
	}
}

//only display larger then 1170px - ONLY LARGE DESKTOP
@mixin b-DL($portrait: false, $landscape: false) {
	@if $portrait {
		@media (min-width: 1371px) and (orientation: portrait) {
			@content;
		}
	} @else if $landscape {
		@media (min-width: 1371px) and (orientation: landscape) {
			@content;
		}
	} @else {
		@media (min-width: 1371px) {
			@content;
		}
	}
}

//only display smaller then 1170px - SMALL DESKTOP AND MOBILE
@mixin b-D($portrait: false, $landscape: false) {
	@if $portrait {
		@media (max-width: 1370px) and (orientation: portrait) {
			@content;
		}
	} @else if $landscape {
		@media (max-width: 1370px) and (orientation: landscape) {
			@content;
		}
	} @else {
		@media (max-width: 1370px) {
			@content;
		}
	}
}

//only display smaller then 1170px - SMALL DESKTOP AND MOBILE
@mixin b-DS($portrait: false, $landscape: false) {
	@if $portrait {
		@media (max-width: 1169px) and (orientation: portrait) {
			@content;
		}
	} @else if $landscape {
		@media (max-width: 1169px) and (orientation: landscape) {
			@content;
		}
	} @else {
		@media (max-width: 1169px) {
			@content;
		}
	}
}

//only display smaller then 991px - LARGE TABLET AND WEBBOOKS
@mixin b-TL($portrait: false, $landscape: false) {
	@if $portrait {
		@media (max-width: 991px) and (orientation: portrait) {
			@content;
		}
	} @else if $landscape {
		@media (max-width: 991px) and (orientation: landscape) {
			@content;
		}
	} @else {
		@media (max-width: 991px) {
			@content;
		}
	}
}

//only display largen then 768px - ALL DESKTOP
@mixin b-TS-UP($portrait: false, $landscape: false) {
	@if $portrait {
		@media (min-width: 768px) and (orientation: portrait) {
			@content;
		}
	} @else if $landscape {
		@media (min-width: 768px) and (orientation: landscape) {
			@content;
		}
	} @else {
		@media (min-width: 768px) {
			@content;
		}
	}
}

//only display smaller then 768px - STANDARD TABLER
@mixin b-TS($portrait: false, $landscape: false) {
	@if $portrait {
		@media (max-width: 767px) and (orientation: portrait) {
			@content;
		}
	} @else if $landscape {
		@media (max-width: 767px) and (orientation: landscape) {
			@content;
		}
	} @else {
		@media (max-width: 767px) {
			@content;
		}
	}
}

//only display smaller then 768px - LARGE MOBILE
@mixin b-ML($portrait: false, $landscape: false) {
	@if $portrait {
		@media (max-width: 650px) and (orientation: portrait) {
			@content;
		}
	} @else if $landscape {
		@media (max-width: 650px) and (orientation: landscape) {
			@content;
		}
	} @else {
		@media (max-width: 650px) {
			@content;
		}
	}
}

//only display smaller then 460px - MOBILE
@mixin b-M($portrait: false, $landscape: false) {
	@if $portrait {
		@media (max-width: 460px) and (orientation: portrait) {
			@content;
		}
	} @else if $landscape {
		@media (max-width: 460px) and (orientation: landscape) {
			@content;
		}
	} @else {
		@media (max-width: 460px) {
			@content;
		}
	}
}

//only display smaller then 350px - SMALL MOBILE
@mixin b-MS($portrait: false, $landscape: false) {
	@if $portrait {
		@media (max-width: 350px) and (orientation: portrait) {
			@content;
		}
	} @else if $landscape {
		@media (max-width: 350px) and (orientation: landscape) {
			@content;
		}
	} @else {
		@media (max-width: 350px) {
			@content;
		}
	}
}

.basicCont {
	padding: 70px 0px 80px 0px;

	@include b-D {
		padding: 40px 0px 45px 0px;
	}

	@include b-ML {
		padding: $spaceF 0px $spaceF 0px;
	}

	h2 {
		font-size: 1.3125rem;
		margin: 0px 0px 25px 0px;
		padding: 0px;

		@include b-ML {
			font-size: 1.1rem;
			margin: 0px 0px 15px 0px;
		}
	}

	.bottomSpace {
		margin-bottom: 60px;
		@include b-ML {
			margin-bottom: 20px;
		}
	}

	p {
		margin: 0;
		padding: 0;
	}
}

.basicBottomSpace {
	margin-bottom: $spaceBig !important;

	@include b-D() {
		margin-bottom: $spaceMid !important;
	}
	@media (max-width: 730px) {
		margin-bottom: $spaceF !important;
	}
}

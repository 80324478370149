.fake {
	background-image: url("../img/carousel-01-anect.jpg");
}
.fake2 {
	background-image: url("../img/carousel-02-gity.jpg");
}
.fake3 {
	background-image: url("../img/carousel-03-partners.jpg");
}

.fake3a {
	background-image: url("../img/carousel-04-porsche.jpg");
}
.fake3b {
	background-image: url("../img/carousel-05-szdc.jpg");
}
.fake3c {
	background-image: url("../img/carousel-06-velska.jpg");
}

.fake4 {
	background-image: url("../img/logopeme.png");
}
.fake5 {
	background-image: url("../img/logopeme.png");
}

.fake6 {
	background-image: url("../img/work-01-szdc.jpg");
}

.fake7 {
	background-image: url("../img/work-02-velska.jpg");
}

.fake8 {
	background-image: url("../img/work-03-porsche.jpg");
}

.fake10 {
	background-image: url("../img/work-04-partners.jpg");
}
.fake11 {
	background-image: url("../img/carousel-07-cez.jpg");
}
.fake12 {
	background-image: url("../img/work-07-cez.jpg");
}
.fake13 {
	background-image: url("../img/carousel-08-heton.jpg");
}
/********* menu styling  *******/
.topHeader {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 10000;

	.brand {
		position: absolute;
		right: 50px;
		top: 50px;
		width: 213px;
		height: auto;
		@media (max-width: 1600px) {
			//width: 3vw;
		}
		@include b-TS() {
			//width: 3vw;
			right: 30px;
			top: 30px;
			width: 170px;
		}
		@include b-ML() {
			//width: 3vw;
			right: 15px;
			top: 15px;
			width: 100px;
		}
	}

	.mainMenu {
		display: inline-block;
		margin: 50px 0px 0px 50px;
		padding: 0;

		@include b-TS() {
			margin-left: 30px;
			margin-top: 30px;
		}
		@include b-ML() {
			margin-left: 15px;
			margin-top: 15px;
		}
		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}
		li {
			margin: 0px 0px 0px 0px;
			&:last-child {
				margin: 0px 0px 0px 0px;
			}
			@include b-ML() {
				margin: 0px 0px 0px 0px;
			}
			a {
				color: $white;
				display: block;
				font-size: 1rem;
				font-weight: 300;
				text-decoration: none;
				font-family: "RationalBook", sans-serif;
				padding: 5px 0px 0px 0px;
				text-transform: uppercase;

				@include trans();
				&:hover {
					color: $yelow;
					font-weight: 300;
					text-decoration: underline;
					@include trans();
				}

				@include b-TS() {
					font-size: 1rem;
				}
				@include b-ML() {
					padding: 5px 0px 0px 0px;
					font-size: 0.8rem;
				}
			}
			&.active {
				a {
					color: $yelow;
					font-weight: 300;
					&:hover {
						color: $yelow;
						@include b-menuM() {
							color: $red;
						}
					}
				}
			}
		}
	}
}
.toTop {
	display: block;
	position: fixed;
	right: 50px;
	bottom: 50px;
	width: 80px;
	height: 80px;
	cursor: pointer;

	background-image: url("../img/arrow-top.svg");
	background-size: 80px 80px;

	@include b-ML() {
		background-size: 40px 40px;
		width: 40px;
		height: 40px;
		right: 15px;
		bottom: 15px;
		opacity: 0.4;
	}
}
/********* HP carousel styling *******/
.HPCarousel {
	position: relative;
	overflow: hidden;
	button[data-action="stop"],
	button[data-controls="prev"],
	button[data-controls="next"] {
		display: none;
	}

	h1 {
		position: absolute;
		left: 0px;
		top: 33%;
		text-align: center;
		font-size: 3.5rem;
		color: $white;
		z-index: 100;
		width: 100%;

		@media (max-width: 1300px) {
			top: 30%;
			font-size: 2.5rem;
		}
		@include b-TL() {
			top: 25%;
			font-size: 2.1rem;
		}
		@include b-ML() {
			top: 39%;
			font-size: 1.4rem;
		}

		span {
			color: $white;
			display: block;

			&:first-child {
				margin-left: -10%;
				@include b-TL() {
					margin-left: -8%;
				}
				@include b-ML() {
					margin-left: -60px;
				}
			}
			&:last-child {
				margin-left: 10%;
				@include b-TL() {
					margin-left: 8%;
				}
				@include b-ML() {
					margin-left: 30px;
				}
			}
		}
	}

	&--linkWrap {
		z-index: 100;
		width: calc(100% - 300px);
		height: 100px;
		position: absolute;
		top: 61%;
		left: 150px;
		border-top: 1px solid $white;
		border-bottom: 1px solid $white;
		@include b-TL() {
			width: calc(100% - 100px);
			left: 50px;
			height: 70px;
		}
		@include b-ML() {
			width: calc(100% - 80px);
			left: 40px;
			height: 40px;
			top: 50%;
		}
		@media (max-width: 460px) {
			top: 60%;
		}
	}

	&--scrollwrap {
		margin: 0px auto;
		padding: 0;
		display: block;
		width: auto;
		position: absolute;
		bottom: 60px;
		left: 0;
		width: 100%;
		z-index: 100;

		text-align: center;

		@media (max-width: 1200px) {
			bottom: 40px;
		}
		@media (max-width: 900px) {
			bottom: 20px;
		}
		@media (max-width: 560px) {
			bottom: 11vh;
		}
		@media (max-width: 460px) {
			bottom: 20px;
		}
		ul {
			margin: 0px auto;
			padding: 0;
			display: inline-block;
			width: auto;
			list-style-type: none;
			/*
			-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);*/
		}

		li {
			float: left;
			margin-right: 30px;
			font-size: 1rem;
			&:last-child {
				margin-right: 0px;
			}
			@include b-TS() {
				font-size: 0.7rem;
			}
			@media (max-width: 560px) {
				margin-right: 0px;
				float: none;
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0px;
				}
			}
		}
		a {
			color: $white;
			@include trans();
			&:hover {
				@include trans();
				color: $yelow;
				text-decoration: none;
			}

			font-weight: 300;
			text-decoration: none;
			font-family: "RationalBook", sans-serif;
			text-transform: uppercase;

			@include trans();
			&:hover {
				color: $yelow;
				font-weight: 300;
				text-decoration: underline;
				@include trans();
			}

			@include b-TS() {
			}
			@include b-ML() {
				font-size: 0.8rem;
			}
		}
	}
	.hpsliderWrap {
		position: relative;
		height: 90vh;
		@include b-ML() {
			height: 80vh;
		}
	}

	.hpslider--item {
		width: 100%;
		height: 90vh;
		position: relative;

		@include b-ML() {
			height: 80vh;
		}
		img {
			//display: block;
			height: 100%;
			width: auto;
		}
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		h2 {
			position: absolute;
			text-align: center;
			font-family: "RationalSemibold", sans-serif;
			color: $yelow;
			font-size: 2rem;
			width: 100%;
			left: 0px;
			top: calc(61% + 25px);
			@include b-TL() {
				top: calc(61% + 19px);
				font-size: 1.4rem;
			}
			@include b-ML() {
				top: calc(50% + 10px);
				font-size: 1rem;
			}
			@media (max-width: 460px) {
				top: calc(60% + 9px);
			}
		}
	}
}
/********** HP works ****************/
.hpWorks {
	&--imgHolder {
		img {
			width: 100%;
		}
	}
	&--txtHolder {
		h2 {
			font-size: 1.75rem;
			margin-bottom: 30px;
			@include b-ML() {
				font-size: 1.4rem;
				margin-bottom: 15px;
			}
		}
		@include b-ML() {
			font-size: 0.8rem;
			padding: 30px 15px !important;
		}
	}
	&--galleryLink {
		display: block;
		color: $txt;
		text-decoration: underline;
		margin-top: 20px;
		text-transform: uppercase;
		@include trans();
		font-family: "RationalBook", sans-serif;
		&:hover {
			color: $yelow;
			@include trans();
		}
	}
	&--row {
		align-items: stretch;
		&:nth-child(even) {
			div {
				&:first-child {
					order: 2;
					@media (max-width: 959px) {
						order: 1;
					}
				}
				&:last-child {
					order: 1;
					@media (max-width: 959px) {
						order: 2;
					}
				}
			}
		}
	}
}
/********** aktivity ****************/
.activity {
	background-image: url("../img/back-about.jpg");
	background-position: center center;
	background-size: cover;

	&--padding {
		padding: 100px;
		@include b-TS() {
			padding: 50px;
		}
		@include b-ML() {
			padding: 30px 15px !important;
		}
	}
	article {
		color: $white;
		p {
			color: $white;
		}
		h2 {
			color: $white;
			font-size: 1.75rem;
			margin-bottom: 30px;
			@include b-ML() {
				font-size: 1.4rem;
				margin-bottom: 15px;
			}
		}
		@include b-ML() {
			font-size: 0.8rem;
		}

		border-bottom: 1px solid $white;
		margin-bottom: 50px;
		@include b-ML() {
			margin-bottom: 20px;
		}
		&:last-child {
			border-bottom: none;
			margin-bottom: 0px;
		}
	}
	&--howLink {
		color: $white;
		text-align: right;
		text-decoration: underline;
		margin-top: 20px;
		text-transform: uppercase;
		@include trans();
		font-family: "RationalBook", sans-serif;
		&:hover {
			color: $yelow;
			@include trans();
		}
	}
	.uk-text-right {
		@include b-ML() {
			margin-bottom: 20px;
		}
	}
}

/********** PATICKA **************/
.foot {
	&--padding {
		font-family: "RationalBook", sans-serif;
		padding: 100px;
		@include b-TS() {
			padding: 50px;
		}
		@include b-ML() {
			padding: 30px 15px !important;
			font-size: 0.7rem;
		}
		p {
			font-family: "RationalBook", sans-serif;
			@include b-ML() {
				margin-bottom: 20px;
			}
		}
		h2 {
			font-size: 1.75rem;
			font-family: "RationalSemibold", sans-serif;
			margin-bottom: 30px;
			@include b-ML() {
				font-size: 1.4rem;
				margin-bottom: 15px;
			}
		}

		td {
			vertical-align: top;
			padding-top: 30px;
			&:nth-child(2) {
				padding-left: 20px;
			}
		}

		hr {
			border-top: 1px solid #000000;
		}
		a {
			color: $txt;
			text-decoration: none;
			text-transform: uppercase;
			@include trans();
			font-family: "RationalBook", sans-serif;
			&:hover {
				color: $yelow;
				@include trans();
				text-decoration: underline;
			}
		}
		i {
			font-family: "RationalBookItalic", sans-serif;
			font-weight: 300;
		}
	}
}

/********** LIGHTBOX **************/
.uk-lightbox {
	background-color: rgba(99, 22, 75, 0.8);
}
.uk-lightbox-toolbar {
	display: none;
}
.uk-lightbox-button {
	width: 80px;
	height: 80px;
	background: transparent;
	color: rgba(255, 255, 255, 0.7);
	img,
	svg {
		display: none;
	}

	@media (max-width: 650px) {
		width: 30px;
		height: 30px;
	}
}
.uk-slidenav-next {
	background-image: url("../img/arrow-right.svg");
	background-size: 80px 80px;
	background-repeat: no-repeat;
	@media (max-width: 650px) {
		background-size: 30px 30px;
	}
}
.uk-slidenav-previous {
	background-image: url("../img/arrow-left.svg");
	background-size: 80px 80px;
	background-repeat: no-repeat;
	@media (max-width: 650px) {
		background-size: 30px 30px;
	}
}
.uk-lightbox-items {
	img {
		padding: 60px;

		@media (max-width: 768px) {
			padding: 0px;
		}
	}
}
/********** SUPPLIERS **************/
.suppliersWrap,
.customersWrap,
.historyWrap {
	min-height: 100vh;
	background-image: url("../img/back-suppliers.jpg");
	background-size: cover;
	background-attachment: fixed;
	background-position: center center;
	position: relative;
	padding: 100px;

	@include b-TS() {
		padding: 50px;
	}
	@include b-ML() {
		padding: 40px 15px 30px 15px;
		font-size: 0.8rem;
	}

	p {
		color: $white;
		margin-bottom: 30px;
		@include b-ML() {
			margin-bottom: 15px;
		}
	}

	h1 {
		color: $white;
		font-size: 1.75rem;
		margin-bottom: 30px;
		@include b-ML() {
			font-size: 1.4rem;
			margin-bottom: 15px;
		}
	}
}

.backNav {
	position: absolute;
	width: 100%;
	left: 0px;
	color: $white;
	top: 10px;
	z-index: 1000;

	@include b-ML() {
		top: -30px;
	}
	a {
		color: $white;
		text-decoration: underline;
		@include trans();
		display: block;
		text-align: right;
		text-transform: uppercase;
		padding-right: 0px;

		@include b-ML() {
			font-size: 0.8rem;
		}
		&:hover {
			@include trans();
			color: $yelow;
		}
	}
}

.customersWrap {
	background-image: url("../img/back-customers.jpg");
}
.historyWrap {
	background-image: url("../img/back-history.jpg");
	color: $white;
	h1,
	p,
	a {
		color: $white;
	}
}
.logoGallery {
	display: flex;
	flex-wrap: wrap;

	&--item {
		width: calc(20% - 2px);
		float: left;
		background-color: $white;
		margin-right: 2px;
		margin-bottom: 2px;

		@media (max-width: 1240px) {
			width: calc(25% - 2px);
		}
		@media (max-width: 990px) {
			width: calc(33% - 2px);
		}
		@media (max-width: 560px) {
			width: calc(50% - 1px);
			margin-right: 1px;
			margin-bottom: 1px;
		}
		@media (max-width: 340px) {
			width: calc(100%);
			margin-right: 0px;
			margin-bottom: 1px;
		}
	}
	&--inner {
		padding: 30px;

		color: $txt;
		text-align: center;

		img {
			width: 100%;
			padding-bottom: 30px;
			border-bottom: 1px solid $txt;
			margin-bottom: 30px;
		}
	}
}

#startBlackBox {
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0px;
	top: 0px;
	background-color: #000000;
	z-index: 1000000000;
	animation: paraOpaOut 0.75s ease-in-out 0s 1 forwards;
}
.leaveBlackBox {
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0px;
	top: 0px;
	background-color: #000000;
	z-index: 1000000000;
	animation: paraOpaIn 0.75s ease-in-out 0s 1 forwards;
}

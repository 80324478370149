// variables START

$basic-width: 1600px;
$basic-width-mid: 1600px;
$mid-width: 1200px;
$b-addBorder: "@media (max-width: (${basic-width} + (${space} * 2))) ";

//grid system

$a-land: "and (orientation: landscape)";
$a-port: "and (orientation: portrait)";
$land: "(orientation: landscape)";
$port: "(orientation: portrait)";
$b-LD: "(min-width: 1170px)";
$b-SD: "(max-width: 1169px)";
$b-LT: "(max-width: 991px)";
$b-STm: "(min-width: 768px)";
$b-ST: "(max-width: 767px)";
$b-LM: "(max-width: 650px)";
$b-M: "(max-width: 460px)";
$b-SM: "(max-width: 350px)";

//colours#E5D1D2
$txt: #000000;
$black: #000000;
$back: #ffffff;
$green: #61c250;
$green-dark: #277818;
$blue: #0083bc;
$blue-violet: #7582b6;
$blue-foot: #334074;
$blue-back: #283567;
$white: #ffffff;
$red: #d81e04;
$line: #d2d5db;
$grey: #cacaca;
$grey-light: #e4e7ea;
$grey-verylight: #f8f8f8;
$grey-line: #2f3645;
$blue-light: #61779e;
$grey-dark: #707070;
$orange: #fabe63;
$yelow: #fabe63;

$space: 40px;
$spaceF: 20px;
$spaceMid: 20px;
$spaceBig: 80px;

$base-font-size: 20px;
$base-linehight: normal;

$basic-hp-header-height: 100vh;
* {
	//background-image: url("../img/carousel-03-porsche.jpg");
}

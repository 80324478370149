/* form and buttons START */
.btn {
	border-radius: 0px;
	background-color: $txt;
	//background-image: url("./img/arrow-right-white.svg");
	border: none;
	color: $white;
	display: inline-block;
	width: auto;
	text-align: center;
	padding: 15px 40px 15px 20px;
	font-size: 0.8125rem;
	position: relative;
	background-repeat: no-repeat;
	background-size: 9px 14px;
	background-position: right 20px center;
	-webkit-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	transition: all 0.5s ease;
	margin-top: 0px;
	white-space: normal;
	z-index: 1;
	font-weight: 700;
	text-transform: uppercase;
	&:hover,
	&:focus {
		background-color: $blue;
		color: $white;
		text-decoration: none;
		padding: 15px 37px 15px 22px;
		background-position: right 25px center;
	}
}

.form {
	position: relative;
	z-index: 1;

	.er {
		background-color: $red;
		color: $white;
		padding: 20px;
		text-align: left;
		font-weight: 700;
		margin-bottom: $space / 2;
	}

	.ch {
		background-color: $green;
		color: $white;
		padding: 20px;
		text-align: left;
		font-weight: 700;
		margin-bottom: $space / 2;
	}

	label {
		display: inline-block;
		width: 100%;
		max-width: 340px;
		margin-bottom: 6px;
		font-weight: 200;
		text-align: left;
		font-size: 1.176rem;
		color: $white;
		padding-left: 20px;
		margin-left: auto;
		margin-right: auto;
	}

	input,
	textarea {
		width: 100%;
		max-width: 340px;
		display: block;
		margin-bottom: $space / 2;
		background-color: $white;
		padding: 15px 20px 14px 20px;
		border-radius: 27px;
		margin-top: 2px;
		font-size: 1.176rem;
		border: 1px solid $txt;
		margin-left: auto;
		margin-right: auto;
	}

	textarea {
		height: 100px;
	}
	.selectBox {
		width: 100%;
		display: block;
		margin-bottom: 10px;
		background-color: #fff29f;
		border: none;
		padding: 15px 10px;
		border-radius: 40px;
		margin-top: 2px;
		padding-right: 20px;
		//background-image: url(../img/down.png);
		background-repeat: no-repeat;
		background-position: 99% 50%;
		padding-left: 20px;
	}

	select {
		-moz-appearance: window;
		-webkit-appearance: none;
		padding-right: 20px;
		position: relative;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center right;
		border: none;
		width: 100%;
	}

	input.radok + label {
		position: relative;
		display: inline-block;
		padding: 0px 0px 0px 50px;
		background-image: url(../img/radio_off.svg);
		background-size: 31px 31px;
		color: $white;
		background-repeat: no-repeat;
		background-position: left center;
		font-size: 1.76rem;
		cursor: pointer;
		margin-right: 60px;
		top: 0px;
		width: auto;
		height: 31px;
		vertical-align: middle;
		margin-bottom: 20px;
		cursor: pointer;

		@include b-TS() {
			padding: 4px 0px 0px 35px;
			background-size: 21px 21px;
			font-size: 1.26rem;
			margin-right: 30px;
			margin-bottom: 10px;
		}

		&:last-child {
			margin-right: 0px;
		}
		&.mW {
			min-width: 295px;
			margin-right: 0px;
		}
	}

	input.radok:disabled + label {
		opacity: 0.4;
	}

	input.radok:checked + label {
		background-image: url(../img/radio_on.svg);
	}

	input.radok {
		display: none;
	}
	input.radokS + label {
		position: relative;
		display: inline-block;
		padding: 2px 0px 0px 23px;
		background-image: url(../img/radio_off.svg);
		background-size: 18px 18px;
		color: $white;
		background-repeat: no-repeat;
		background-position: left center;
		font-size: 0.88rem;
		cursor: pointer;
		margin-right: 15px;
		top: 0px;
		width: auto;
		height: 20px;
		vertical-align: middle;
		margin-bottom: 20px;
		cursor: pointer;

		@include b-TS() {
			font-size: 0.88rem;
			margin-right: 15px;
			margin-bottom: 10px;
		}

		&:last-child {
			margin-right: 0px;
		}

		a {
			text-decoration: underline;
			color: $white;
			&:hover {
				color: $txt;
			}
		}
	}

	input.radokS:disabled + label {
		opacity: 0.4;
	}

	input.radokS:checked + label {
		background-image: url(../img/radio_on.svg);
	}

	input.radokS {
		display: none;
	}
	input.checkok + label {
		position: relative;
		display: inline-block;
		padding: 7px 0px 0px 50px;
		background-image: url(../img/check_off.svg);
		background-size: 41px 36px;
		color: $white;
		background-repeat: no-repeat;
		background-position: left center;
		font-size: 1.76rem;
		cursor: pointer;
		margin-right: 60px;
		top: 0px;
		width: auto;
		height: 40px;
		vertical-align: middle;
		margin-bottom: 20px;
		cursor: pointer;

		@include b-TS() {
			padding: 10px 0px 0px 35px;
			background-size: 21px 18px;
			font-size: 1.26rem;
			margin-right: 30px;
			margin-bottom: 10px;
		}

		&:last-child {
			margin-right: 0px;
		}
	}

	input.checkok:disabled + label {
		opacity: 0.4;
	}

	input.checkok:checked + label {
		background-image: url(../img/check_on.svg);
	}

	input.checkok {
		display: none;
	}
	input.checkokBig + label {
		position: relative;
		display: block;
		padding: 13px 0px 0px 50px;
		background-image: url(../img/check_off.svg);
		background-size: 41px 36px;
		color: $white;
		background-repeat: no-repeat;
		background-position: left top 2px;
		font-size: 1rem;
		cursor: pointer;
		margin-right: 60px;
		top: 0px;
		width: auto;
		height: auto;
		min-height: 36px;
		vertical-align: middle;
		margin-bottom: 20px;
		cursor: pointer;
		max-width: 100%;
		margin-top: -13px;
		@include b-TS() {
			padding: 10px 0px 0px 35px;
			background-size: 21px 18px;
			font-size: 1rem;
			margin-right: 30px;
			margin-bottom: 10px;
			background-position: left top 10px;
			margin-top: -10px;
		}

		&:last-child {
			margin-right: 0px;
		}

		a {
			text-decoration: underline;
			color: $white;
			&:hover {
				color: $txt;
			}
		}
	}

	input.checkokBig:disabled + label {
		opacity: 0.4;
	}

	input.checkokBig:checked + label {
		background-image: url(../img/check_on.svg);
	}

	input.checkokBig {
		display: none;
	}
	input,
	textarea,
	select {
		outline: none;

		&:focus {
			outline: none;
		}
	}

	.form-center {
		text-align: center;
	}
	.form-note {
		font-size: 1.1rem;
		text-align: left;
		font-weight: bold;
	}

	.form-20 {
		width: calc(24% - #{$spaceF} * 2);
		padding: 0px $spaceF;
		display: inline-block;

		@include b-ML() {
			width: calc(53% - #{$spaceF} * 2);
		}
	}
	.form-30 {
		width: calc(33.3333333% - #{$spaceF} * 2);
		padding: 0px $spaceF;
		display: inline-block;
		@include b-ML() {
			width: 100%;
		}
	}
	.form-20t {
		width: calc(24% - #{$spaceF} * 2);
		padding: 0px $spaceF;
		display: inline-block;

		@include b-ML() {
			width: calc(40% - #{$spaceF} * 2);
		}
	}

	.form-40t {
		width: calc(40% - #{$spaceF} * 2);
		padding: 0px $spaceF;
		display: inline-block;
		@include b-ML() {
			width: calc(66% - #{$spaceF} * 2);
		}
	}

	.form-40 {
		width: calc(40% - #{$spaceF} * 2);
		padding: 0px $spaceF;
		display: inline-block;
		@include b-ML() {
			width: 100%;
		}
	}
	.form-50 {
		width: calc(50% - (#{$spaceF} / 2));
		//padding: 0px $spaceF;
		margin-left: 0px;
		margin-right: 0px;
		display: block;
		float: left;
		@include b-ML() {
			width: 100%;
		}

		&:first-child {
			margin-right: $space;
			@include b-ML() {
				margin-right: 0px;
			}
		}
	}
	.form-full {
		margin-bottom: 0px;
		font-weight: 700;
		text-align: center;
		font-size: 0.9rem;
		width: calc(100% - #{$spaceF} * 2);
		padding: 0px $spaceF;
		@include b-ML() {
			width: 100%;
		}
	}
	.checklabel {
		text-align: left;
		font-size: 0.9rem;
		input {
			width: auto;
			height: 16px;
			display: inline-block;
			margin-right: 12px;
		}
	}
}

/* form and buttons STOP */

/* basic START */
/* montserrat-300 - latin-ext_latin */

@font-face {
	font-family: "RationalBold";
	font-style: normal;
	font-weight: 900;
	src: local("Rational Bold"), local("Rational-Bold"),
		url("../fonts/rationaltext-bold.woff2") format("woff2"),
		url("../fonts/rationaltext-bold.woff") format("woff");
}

@font-face {
	font-family: "RationalSemibold";
	font-style: normal;
	font-weight: 700;
	src: local("Rational Semibold"), local("Rational-Semibold"),
		url("../fonts/rationaltext-semibold.woff2") format("woff2"),
		url("../fonts/rationaltext-semibold.woff") format("woff");
}

@font-face {
	font-family: "RationalBook";
	font-style: normal;
	font-weight: 300;
	src: local("Rational Book"), local("Rational-Book"),
		url("../fonts/rationaltext-book.woff2") format("woff2"),
		url("../fonts/rationaltext-book.woff") format("woff");
}

@font-face {
	font-family: "RationalBookItalic";
	font-style: normal;
	font-weight: 300;
	src: local("Rational Book Italic"), local("Rational-Book-Italic"),
		url("../fonts/rationaltext-bookitalic.woff2") format("woff2"),
		url("../fonts/rationaltext-bookitalic.woff") format("woff");
}

@font-face {
	font-family: "RationalMedium";
	font-style: normal;
	font-weight: 400;
	src: local("Rational Medium"), local("Rational-Medium"),
		url("../fonts/rationaltext-medium.woff2") format("woff2"),
		url("../fonts/rationaltext-medium.woff") format("woff");
}

@font-face {
	font-family: "RationalLight";
	font-style: normal;
	font-weight: 300;
	src: local("Rational Light"), local("Rational-Light"),
		url("../fonts/rationaltext-light.woff2") format("woff2"),
		url("../fonts/rationaltext-light.woff") format("woff");
}

@font-face {
	font-family: "RationalLightItalic";
	font-style: normal;
	font-weight: 300;
	src: local("Rational Light Italic"), local("Rational-Light-Italic"),
		url("../fonts/rationaltext-lightItalic.woff2") format("woff2"),
		url("../fonts/rationaltext-lightItalic.woff") format("woff");
}

html > * {
	color: $txt;
}
body {
	background-color: $white;
	font-family: "RationalLight", sans-serif;
	font-size: $base-font-size;
	line-height: $base-linehight;
	font-weight: 300;
	margin: 0;
	padding: 0px 0px 0px 0px;
	color: $txt;
	letter-spacing: 0rem;
	position: relative;
	z-index: 1;
	@include b-menu2() {
		padding: 0px 0px 0px 0px;
	}
}
* {
	color: $txt;
}
a,
img {
	border: none;
	outline: none;
}

html {
	font-size: $base-font-size;
	line-height: $base-linehight;
	margin: 0;
	padding: 0;
	color: $txt;
	scroll-behavior: smooth;
}

strong,
b {
	font-family: "RationalSemibold", sans-serif;

	font-weight: bold;
	font-weight: 700;
}

i {
	font-family: "RationalLightItalic", sans-serif;
}

input {
	font-family: "RationalLight", sans-serif;
	font-weight: 400;
	color: $txt;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	color: $txt;
	font-family: "RationalBold", sans-serif;
	font-weight: 900;
}

h1 {
	font-size: 1.6rem;
}
h2 {
	font-size: 1.3rem;
}
h3 {
	font-size: 1.1rem;
}
a {
	cursor: pointer;
	color: $txt;
}
p {
	padding: 0;
	margin: 0px 0px $space 0px;
}

.cont {
	//background-color: blue;
	max-width: $basic-width;

	//max-width: calc(100% - (2 * #{$spaceBig}));
	width: calc(100%);

	position: relative;
	margin-left: auto;
	margin-right: auto;
	height: 100%;

	padding: 0 0;
}

.contM {
	//background-color: blue;
	max-width: $basic-width-mid;
	width: calc(100%);
	position: relative;
	margin-left: auto;
	margin-right: auto;
	height: 100%;

	padding: 0 $space;

	@include b-DS() {
		padding: 0 $space;
	}
	@media (max-width: 730px) {
		padding: 0 $spaceF;
	}
}

.contS {
	max-width: 375px;
	width: calc(100%);
	position: relative;
	margin-left: auto;
	margin-right: auto;
	height: 100%;

	padding: 0 20px;

	@media (max-width: 730px) {
		padding: 0 10px;
	}
}

.contNP {
	@extend .cont;
	padding: 0;
	@media (max-width: 730px) {
		padding: 0;
	}
}

.contNP-M {
	@extend .cont;
	padding: 0;
	@include b-D() {
		max-width: 100%;
		padding: 0 $spaceMid;
	}
	@media (max-width: 730px) {
		padding: 0 $spaceF;
	}
}

.cont-50 {
	width: calc((100% - #{$space}) / 2);
	float: left;
	margin-left: $space;

	&:nth-child(even) {
		margin-left: 0px;
	}

	@include b-TS() {
		width: 100%;
		float: none;
		margin-left: 0px;
		margin-bottom: $space;
	}
}

.cont-75 {
	width: calc(75% - (#{$space} / 2));
	float: left;

	@include b-TS() {
		width: 100%;
		float: none;
		margin-bottom: $space;
	}
}

.cont-25 {
	width: calc(25% - (#{$space} / 2));
	float: right;

	@include b-TS() {
		width: 100%;
		float: none;
		margin-bottom: $space;
	}
}

.cont-50L {
	width: calc(50% - (#{$space} / 2));
	float: left;

	@include b-TS() {
		width: 100%;
		float: none;
		margin-bottom: $space;
	}
}

.cont-50R {
	width: calc(50% - (#{$space} / 2));
	float: right;

	@include b-TS() {
		width: 100%;
		float: none;
		margin-bottom: $space;
	}
}

.fullimg {
	width: 100%;
}

.marginBB {
	margin-bottom: $space * 2;
	@include b-TS() {
		margin-bottom: $space;
	}
}
.marginB {
	margin-bottom: $space;
	@include b-TS() {
		margin-bottom: $space;
	}
}

.basic {
	padding: 65px 0px;
	background-color: $txt;
	@include b-TS() {
		padding: 35px 0px;
	}
}

.firstInputTopMargin {
	margin-top: 40px;
}

.ta-c {
	text-align: center;
}

.full-img {
	width: 100%;
}

.mobHid {
	@include b-menuM() {
		display: none !important;
	}
}
.mobVid {
	display: none !important;
	@include b-menuM() {
		display: block !important;
	}
}

.dev {
	width: 300px;
	position: fixed;
	left: 0px;
	top: 0px;
	background-color: rgba(0, 0, 0, 0.5);
	color: $white;
	display: block;
	padding: 10px;
}

/* BOOTSTRAP OVERWRITE STOP */
/* PARALAX */
.paraX {
	opacity: 0;
}
@keyframes para {
	from {
		transform: translateY(30px);
		opacity: 0;
	}
	to {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes paraL {
	from {
		transform: translateX(30px);
		opacity: 0;
	}
	to {
		transform: translateX(0px);
		opacity: 1;
	}
}

@keyframes paraR {
	from {
		transform: translateX(-30px);
		opacity: 0;
	}
	to {
		transform: translateX(0px);
		opacity: 1;
	}
}
.paraAnim {
	animation: para 1s ease-in-out 0s 1 forwards;
}

.paraAnimR {
	animation: paraR 1s ease-in-out 0s 1 forwards;
}

.paraAnim-5 {
	opacity: 0;
	animation: para 1s ease-in-out 0.5s 1 forwards;
}

.paraAnim-5L {
	opacity: 0;
	animation: paraL 1s ease-in-out 0.5s 1 forwards;
}

@keyframes paraOpaOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.paraOpacity10 {
	animation: paraOpaOut 1s ease-in-out 0s 1 forwards;
}

@keyframes paraOpaIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.paraOpacity01 {
	animation: paraOpaOut 1s ease-in-out 0s 1 forwards;
}

/* PARALAX STOP */
